<template>
  <div class="content">
    <div>
      <div class="row">
        <div class="col">
          <input class="mb-2 form-control" type="text" v-model="release.version" placeholder="Release Version eg 1.0.0" :class="{ 'is-invalid': errors.version }" />
          <div v-for="(error, k) in errors.version" :key="'errname'+k" class="invalid-feedback" v-html="errors.version[k]"></div>
        </div>
        <div class="col">
          <input class="mb-2 form-control" type="text" v-model="release.notes" placeholder="Release Notes" />
          <div v-for="(error, k) in errors.notes" :key="'errdes'+k" class="invalid-feedback" v-html="errors.notes[k]"></div>
        </div>
        <div class="col">
          <button class="vave-btn btn-blue" @click="createRelease()">Create new Release</button>
        </div>
      </div>

      <div class="table-container">
        <table class="table">
          <thead class="head">
            <tr>
              <th>Version</th>
              <th>Release Notes</th>
              <th>Author</th>
              <th>Refresh prompted</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="release in releases.data" :key="release.id">
              <td><span class="bold blue">{{release.version}}</span></td>
              <td>
                <span class="">{{release.notes}}</span>
              </td>
              <td>
                <span class="pale small">{{release.user}}</span>
              </td>
              <td>
                <span class="pale small">{{release.prompted_refreshes}}</span>
              </td>
              <td><span class="pale">{{release.created_at | formattedLongDate}}</span></td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination class="paginator" :limit="4" align="center" :data="releases" @pagination-change-page="getReleases"></pagination>
    </div>

  </div>
</template>

<script>
import axios from "./../http.js";
import pagination from "laravel-vue-pagination";
import moment from 'moment';

export default {
  data() {
    return {
      release: {
        name: "",
        description: "",
        file: null,
      },
      errors: {},
      releases: {},
    };
  },
  mounted() {
    this.getReleases();
  },
  filters: {
    formattedLongDate(value) {
      return value ? moment(String(value)).format("DD MMMM YY, HH:mm") : "";
    },
  },
  methods: {
    getReleases(page = 1) {
      this.releases = {};
      axios.get(process.env.VUE_APP_VAVEURL + "/api/admin/releases?per_page=20&page=" + page).then(
        (r) => {
          if (r.status == 200) {
            this.releases = r.data;
          }
        },
        (e) => {
          console.log(e);
        }
      );
    },
    createRelease() {
      // Confirmation
      if( !confirm("Are you really sure you want to manually create this release? You should never manually create a release, intstead just change the version number in the package.json file of vave frontend and deploy on Forge.") ){
        return;
      }
      this.errors = {};
      axios
        .post("/api/admin/releases", {
          version: this.release.version,
          notes: this.release.notes,
        })
        .then(
          (r) => {
            if (r.status == 201) {
              this.release = {
                version: null,
                notes: null,
              };
              this.$toast.success("Release created");
              this.getReleases();
            }
          },
          (e) => {
            this.$toast.error("Error creating release " + e.response.data.message);
            this.errors = e.response.data.errors;
          }
        );
    },
  },
  components: {
    pagination,
  },
};
</script>

<style lang="scss">
tbody tr {
  &:hover {
    background: #fafafa;
  }
}
</style>